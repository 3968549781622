import { initializeApp } from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/messaging";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const fbConfig = {
  // apiKey: process.env.FB_API_KEY,
  // authDomain: process.env.FB_AUTH_DOMAIN,
  // databaseURL: process.env.FB_DATABASE_URL,
  // projectId: process.env.FB_PROJECT_ID,
  // storageBucket: process.env.FB_STORAGE_BUCKET,
  // messagingSenderId: process.env.FB_MESSAGING_SENDER_ID,
  // appId: process.env.FB_APP_ID,
  // measurementId: process.env.FB_MEASUREMENT_ID,
  apiKey: "AIzaSyAihccA09G5F06o5f0TkoknzZAVcofgrnw",
  authDomain: "apis-document.firebaseapp.com",
  projectId: "apis-document",
  storageBucket: "apis-document.appspot.com",
  messagingSenderId: "220402273097",
  appId: "1:220402273097:web:b6aa9998aed495edf66898",
  measurementId: "G-CX17QLBV78",
};

const firebase = initializeApp(fbConfig);
const db = getFirestore(firebase);
const auth = getAuth(firebase);
const storage = getStorage(firebase);

getAnalytics(firebase);

export default firebase;

export { auth, db, storage };
